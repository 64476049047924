<template>
  <v-client-table
    id="dataTable"
    :data="data"
    :options="options"
    :columns="columns"
    :theme="theme"
  >
    <span
      slot="reportDate"
      slot-scope="props"
    >
      <span v-if="period === 'week'">
        {{ formatWeek(props.row.date) }}
      </span>
      <span v-else-if="period === 'month'">
        {{ formatMonth(props.row.date) }}
      </span>
      <span v-else>
        {{ formatDate(props.row.date) }}
      </span>
    </span>
    <span 
      slot="startingPriceSum"
      slot-scope="props"
    >
      {{ numberRUFormat(Math.round(props.row.startingPriceSum)) }}
    </span>
    <span 
      slot="endPriceSum"
      slot-scope="props"
    >
      {{ numberRUFormat(Math.round(props.row.endPriceSum)) }}
    </span>
    <span
      slot="savingOverrun"
      slot-scope="props"
    >
      <span
        v-if="(props.row.startingPriceSum - props.row.endPriceSum) > 0"
        class="text-success"
      >{{ numberRUFormat(Math.round(props.row.startingPriceSum - props.row.endPriceSum)) }} руб.</span>
      <span
        v-else-if="(props.row.startingPriceSum - props.row.endPriceSum) < 0"
        class="text-danger"
      >{{ numberRUFormat(Math.round(props.row.startingPriceSum - props.row.endPriceSum)) }} руб.</span>
      <span v-else>0 руб.</span>
    </span>
    <tr
      slot="appendBody"
    >
      <td />
      <td />
      <td />
      <td />
      <td>
        {{ total.total }}
      </td>
    </tr>
  </v-client-table>
</template>

<script>
import moment from 'moment';
import {numberToRUFormat} from '@/components/mixins/helpers';

export default {
  name: 'RouteNumberDistributionReportTable',
  props: {
    data: {
      type: Array,
      default: function() {
        return [];
      },
    },
    total: {
      type: Object,
      default: function() {
        return {};
      },
    },
    period: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columns: [
        'reportDate',
        'tenders',
        'auctions',
        'nowhere',
        'total',
      ],
      options: {
        headings: {
          'reportDate': 'Период',
          'tenders': 'Рейсы по тендерам, шт',
          'auctions': 'Рейсы по аукционам, шт',
          'nowhere': 'Рейсы распределены вручную, шт',
          'total': 'Всего рейсов',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        perPage: 10,
        perPageValues: [10, 25, 50],
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      theme: 'bootstrap4',
    };
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    },
    formatWeek(date) {
      return date ? moment(date).format('WW Неделя YYYY') : '';
    },
    formatMonth(date) {
      return date ? moment(date).format('MM.YYYY') : '';
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    }
  },
};
</script>

<style scoped>

</style>
